import ScrollToTopButton from "components/ScrollToTopButton";
// import PeriodFilter from "components/PeriodFilter";
import Layout from "components/layout/Layout";
import useFormatPrice from "hooks/useFormatPrice";
import useIntersect from "hooks/useIntersect";
import useLanguages from "hooks/useLanguages";
import TWCardLayout from "components/tailwind/TWCardLayout";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import UserService, { PointHistoryType } from "services/userService";
import { providerClasses } from "utils/tailwindUtils";

const getPointEventType = (type: PointHistoryType["eventType"]) => {
  switch (type) {
    case "SAVING":
      return <FormattedMessage id="point.earn" />;
    case "USING":
      return <FormattedMessage id="point.use" />;
    case "EXPIRY":
      return <FormattedMessage id="point.expiration" />;
    case "RETURN":
      return <FormattedMessage id="point.refund" />;
    default:
      const _exhaustiveCheck: never = type;
      return _exhaustiveCheck;
  }
};

const PointHistory = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();

  const [pointHistories, setPointHistories] = useState<PointHistoryType[]>([]);
  const formatPrice = useFormatPrice();

  const [last, setLast] = useState(false);
  const [number, setNumber] = useState(-1);
  const [isFetching, setIsFetching] = useState(false);
  const [isScrollToTopButtonShow, setIsScrollToTopButtonShow] = useState(false);

  const infiniteScrollingTarget = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (!last && !isFetching) {
      fetchHistories();
    }
  });

  const scrollToTopButtonTarget = useIntersect(
    () => setIsScrollToTopButtonShow(false),
    () => setIsScrollToTopButtonShow(true)
  );

  const fetchHistories = useCallback(async () => {
    setIsFetching(true);

    try {
      const response = await UserService.getPointHistory(number + 1);
      setPointHistories((prev) => [...prev, ...response.content]);
      setNumber(response.number);
      setLast(response.last);
    } catch (error) {
      console.error("Error while fetching point history", error);
    } finally {
      setIsFetching(false);
    }
  }, [number]);

  useEffect(() => {
    // First history load
    if (number === -1) {
      fetchHistories();
    }
  }, [fetchHistories, number]);

  const getHeader = (
    createdDate: PointHistoryType["createdDate"],
    type: PointHistoryType["eventType"]
  ) => {
    return (
      <div className="flex w-full items-center justify-between gap-2 break-keep">
        <p className={`font-bold ${providerClasses["text-secondary"]}`}>
          {getPointEventType(type)}
        </p>

        <p className="font-bold text-text-secondary">
          {new Date(createdDate).toLocaleDateString(intl.locale)}
        </p>
      </div>
    );
  };

  const getAdditionalInfo = (expireDate: string) => {
    return (
      <div className="w-full text-end">
        <p className="text-sm font-bold text-text-secondary">
          {intl.formatMessage({ id: "point.expiration_date" })}:{" "}
          {new Date(expireDate).toLocaleDateString(intl.locale)}
        </p>
      </div>
    );
  };

  return (
    <Layout text={intl.formatMessage({ id: "point.points_earning_history" })}>
      <div ref={scrollToTopButtonTarget}></div>
      {/* <PeriodFilter period={period} handlePeriodChange={handlePeriodChange} /> */}

      <div className="space-y-2 py-4">
        {pointHistories.length > 0 ? (
          pointHistories.map(
            ({
              createdDate,
              eventType,
              amount,
              savingDesc,
              orderId,
              expireDate,
            }) => (
              <TWCardLayout
                key={createdDate}
                header={getHeader(createdDate, eventType)}
                additionalInfo={expireDate && getAdditionalInfo(expireDate)}
              >
                {savingDesc && <p>{savingDesc}</p>}
                {orderId && (
                  <div className="flex justify-between">
                    <FormattedMessage id="orders.orderId" />: <p>{orderId}</p>
                  </div>
                )}
                <div className="flex justify-between">
                  <FormattedMessage id="point.point" />:
                  <p>{formatPrice(amount)}</p>
                </div>
              </TWCardLayout>
            )
          )
        ) : (
          <div className="flex min-h-[50vh] items-center justify-center">
            <p className="text-xl font-medium">
              {isKorean ? "적립 내역이 없습니다." : "No history found."}
            </p>
          </div>
        )}
      </div>
      {isScrollToTopButtonShow && <ScrollToTopButton />}
      <div ref={infiniteScrollingTarget}></div>
    </Layout>
  );
};

export default PointHistory;
