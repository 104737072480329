import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import TWCardLayout from "components/tailwind/TWCardLayout";

const NewCard = () => {
  const navigate = useNavigate();
  const newCardRef = useRef<HTMLDivElement>(null);

  const navigateToRegisterCard = () => {
    navigate("/card/register");
  };

  useEffect(() => {
    newCardRef.current?.addEventListener("click", (e) => {
      e.preventDefault();
    });

    return;
  });

  return (
    <div ref={newCardRef}>
      <TWCardLayout className="mx-1 bg-gray-300">
        <div
          className="flex h-[calc((100vw-32px)/2.1)] max-h-60 min-h-32 w-full flex-col items-center justify-center"
          onClick={navigateToRegisterCard}
        >
          <AddCircleOutlineIcon color="action" className="!text-7xl" />
          <p className="font-bold text-gray-700">
            <FormattedMessage id="card.addNewCard" />
          </p>
        </div>
      </TWCardLayout>
    </div>
  );
};

export default NewCard;
