/**
 * This file contains constants related to the environment of the application.
 * The values of the constants are determined by the value of the `REACT_APP_ENVIRONMENT` environment variable.
 * The `REACT_APP_ENVIRONMENT` environment variable is set using custom scripts in `package.json`.
 */

import { ProviderUtils } from "utils/providerUtils";

let apiBaseUrl: string;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case "prod":
    if (!ProviderUtils.provider) {
      apiBaseUrl = "https://api.lacha.co.kr";
      break;
    }
    apiBaseUrl = "https://apinew.hcclab.co.kr";
    break;
  case "dev":
    apiBaseUrl = "https://devfo.hcclab.com";
    break;
  case "playground":
    apiBaseUrl = "https://devpg.hcclab.com"; // playground
    apiBaseUrl = "https://devfo.hcclab.com";
    break;
  case "staging":
    apiBaseUrl = "https://staging.hcclab.co.kr";
    break;
  default:
    apiBaseUrl = "http://localhost:8081";
    apiBaseUrl = "https://devfo.hcclab.com";
  // apiBaseUrl = "https://staging.hcclab.co.kr";
  // apiBaseUrl = "https://api.lacha.co.kr";
}

export default apiBaseUrl;

/*
 * The values of the constants are:
 * - `__DEV__`: a boolean indicating whether the environment is development.
 * - `__LOCAL__`: a boolean indicating whether the environment is local (i.e. the application is running on localhost).
 * - `__PROD__`: a boolean indicating whether the environment is production.
 * - `__STAGING__`: a boolean indicating whether the environment is staging.
 */

// localhost - npm start
// export const __DEV__ = process.env.NODE_ENV === "development";
export const __DEV__ = process.env.REACT_APP_ENVIRONMENT === "dev";
export const __LOCAL__ = process.env.NODE_ENV === "development";

// build result - npm run build
// export const __PROD__ = process.env.NODE_ENV === "production";
export const __PROD__ = process.env.REACT_APP_ENVIRONMENT === "prod";
export const __STAGING__ = process.env.REACT_APP_ENVIRONMENT === "staging";

/* 
  process.env.NOED_ENV === "developmemnt" when "npm start" runs
  process.env.NOED_ENV === "production" when "npm run build" runs

  process.env.REACT_APP_ENVIRONMENT has been set using custom scripts in package.json
  and is only set when "npm run build" runs. So, when "npm start" runs, process.env.REACT_APP_ENVIRONMENT is undefined.

  const __LOCAL__ = process.env.NODE_ENV === "development";
  const __DEV__ = process.env.REACT_APP_ENVIRONMENT === "dev";
  const __PROD__ = process.env.REACT_APP_ENVIRONMENT === "prod";
  const __STAGING__ = process.env.REACT_APP_ENVIRONMENT === "staging";
*/
