import StyleIcon from "@mui/icons-material/Style";
import TravelPackBanner from "components/lottecard/TravelPackBanner";
import { ProviderUtils } from "utils/providerUtils";
import TWCardLayout from "components/tailwind/TWCardLayout";
import { providerClasses } from "utils/tailwindUtils";
import { FormattedMessage } from "react-intl";

const Banner = () => {
  const getBannerContent = () => {
    if (ProviderUtils.isLottecard) {
      return <TravelPackBanner />;
    }

    if (ProviderUtils.isHanacard) {
      return (
        <p className="text-start text-[0.9rem] font-bold text-text-secondary">
          <FormattedMessage id="reward.promoBannerHanaCaption1" />
          <br />
          <FormattedMessage id="reward.promoBannerHanaCaption2" />
        </p>
      );
    }

    return (
      <div className="grid min-h-[80px] items-center justify-between">
        <div className="col-span-9">
          <p className="text-base font-bold leading-5">
            <FormattedMessage id="reward.promoBanner1" />
          </p>
          <p className="break-keep text-2xl font-bold leading-7">
            <FormattedMessage id="reward.promoBanner2" />
          </p>
        </div>
        <div className="col-span-3">
          <StyleIcon
            sx={{
              fontSize: `150px`,
              position: "absolute",
              top: -20,
              right: -40,
              transform: "scaleY(-1)",
              // color: "grey.700",
              color: "homeBanner.textColor",
              opacity: "0.7",
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <TWCardLayout
      className={`${providerClasses["bg-banner"]} relative border-none px-6 py-4`}
    >
      {getBannerContent()}
    </TWCardLayout>
  );
};

export default Banner;
