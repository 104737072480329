import moment from "moment";
import DateWrapper from "components/layout/DateWrapper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import TWCardLayout from "components/tailwind/TWCardLayout";

const onwDayFromToday = moment().add(1, "days");
const oneMonthFromToday = moment().add(1, "months");

interface DateSelectorProps {
  date: string;
  onPrev: () => void;
  onNext: () => void;
}

const DateSelector = ({ date, onPrev, onNext }: DateSelectorProps) => {
  const isPreviousDisabled = moment(date).isSameOrBefore(
    onwDayFromToday,
    "day"
  );
  const isNextDisabled = moment(date).isSameOrAfter(oneMonthFromToday, "day");

  return (
    <TWCardLayout className="rounded-tl-none rounded-tr-none p-0">
      <DateWrapper>
        <section className="flex items-center justify-center">
          <button
            className="flex items-center justify-center p-0 disabled:opacity-50"
            disabled={isPreviousDisabled}
          >
            <NavigateBeforeIcon
              onClick={
                moment(date).isAfter(onwDayFromToday, "day")
                  ? onPrev
                  : undefined
              }
              color={isPreviousDisabled ? undefined : "primary"}
            />
          </button>
          <p>{date}</p>
          <button
            className="flex items-center justify-center p-0 disabled:opacity-50"
            disabled={isNextDisabled}
          >
            <NavigateNextIcon
              onClick={
                moment(date).isBefore(oneMonthFromToday, "day")
                  ? onNext
                  : undefined
              }
              color={isNextDisabled ? undefined : "primary"}
            />
          </button>
        </section>
      </DateWrapper>
    </TWCardLayout>
  );
};

export default DateSelector;
