import ActivityDetailLayout from "../components/ActivityDetailLayout";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Checkbox, Divider, FormControlLabel, Skeleton } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import { forwardRef, useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import TravelService, {
  GoodCalendarDetail,
  GoodOption,
  TravelGood,
} from "services/travelService";
import TimeService from "services/timeService";
import moment, { Moment } from "moment";
import TWCardLayout from "components/tailwind/TWCardLayout";
import ActivityOptionSkeleton from "../components/skeleton/ActivityOptionSkeleton";
import { ActivityReservationContext } from "context/ActivityContextx";

import LoadingSpinner from "components/LoadingSpinner";
import BookerInfoSection from "pages/ktx/payment-panel/sections/BookerInfoSection";
import useValidate from "hooks/useValidate";
import useDebounce from "hooks/useDebounce";
import { useMyInfoQuery } from "app/apiSlice";
import ActivityOption from "../components/ActivityOption";
import PassengerCount from "../components/PassengerCount";
import PassengerDetail from "../components/PassengerDetail";

const initialBookerValidState = {
  name: true,
  phone: true,
  email: true,
};

interface ProductTypeSectionProps {
  title: string;
  activity: TravelGood;
}

const ProductTypeSection = forwardRef<HTMLDivElement, ProductTypeSectionProps>(
  ({ title, activity }, ref) => {
    const debounce = useDebounce();

    const {
      setBookerInfo,
      bookerInfo,
      travelDate,
      setTravelDate,
      productList,
      setProductList,
    } = useContext(ActivityReservationContext);
    // const { userId, name, email, phone } = useAppSelector(
    //   (state) => state.user
    // );

    const isPassengerInfoRequired = activity?.isRequireEveryone === 1;

    const { data: user } = useMyInfoQuery();
    // const { userId, name, email, phone } = user || {};

    const isBookerInfoVisible = !(
      bookerInfo.name?.trim() &&
      bookerInfo.phone?.trim() &&
      bookerInfo.email?.trim()
    );
    const [bookerValidState, bookerValidate] = useValidate(
      initialBookerValidState
    );

    const [isCalendarLoading, setIsCalendarLoading] = useState(true);
    const [activityCalendar, setActivityCalendar] =
      useState<GoodCalendarDetail>();

    const [currentDate, setCurrentDate] = useState("");
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);
    const [productOptions, setProductOptions] = useState<GoodOption[]>();

    // 현재 날짜 기준으로 재고 있는지 날짜 불러오기
    useEffect(() => {
      const getActivityCalendar = async () => {
        const currentDate = new Date(await TimeService.getCurrentTime())
          .toISOString()
          .split("T")[0]; // YYYY-MM-DD

        setCurrentDate(currentDate);
      };

      setIsCalendarLoading(true);
      if (!currentDate) {
        getActivityCalendar().finally(() => {
          setIsCalendarLoading(false);
        });
      } else {
        debounce(() => {
          TravelService.getCalendar(activity.goodsId, currentDate)
            .then(({ data }) => {
              setActivityCalendar(data);
            })
            .finally(() => {
              setIsCalendarLoading(false);
            });
        });
      }
    }, [activity.goodsId, currentDate, debounce]);

    // 날짜 클릭 시에 해당 날짜의 상품 불러오기
    useEffect(() => {
      const getGoodOption = async () => {
        setIsOptionsLoading(true);

        TravelService.getGoodOption(activity.goodsId, travelDate)
          .then(({ data }) => {
            const todayOptions = activityCalendar?.[travelDate];

            const dataWithStock: GoodOption[] = data.map((option) => {
              const stock = todayOptions?.[option.apiProductId] ?? 0;
              return { ...option, stock };
            });

            setProductOptions(dataWithStock);
          })
          .finally(() => setIsOptionsLoading(false));
      };

      if (travelDate) {
        getGoodOption();
      }
    }, [activity.goodsId, activityCalendar, travelDate]);

    const changeDate = (e: Moment) => {
      const newSelectedDate = e.format().split("T")[0];
      setProductList([]);
      setTravelDate(newSelectedDate);
    };

    const updateMonth = (e: Moment) => {
      const newCalendarDate = e.format().split("T")[0];
      setProductOptions([]);
      setCurrentDate(newCalendarDate);
    };

    return (
      <ActivityDetailLayout ref={ref} title={title}>
        <div className="space-y-8">
          <div className="space-y-3">
            {/* TODO: 인원 수 변경하면 다시 요청 */}
            <PassengerCount max={activity.maxTicketAmount} />
            {/* {!!productOptions && <NumberSelection />} */}

            <TWCardLayout
              className="relative p-0"
              header={
                <div className="flex items-center gap-2 px-4 pt-2">
                  <CalendarMonthIcon />
                  <p className="font-bold">사용일</p>
                </div>
              }
            >
              {/* 해당 날짜에 상품 있는 경우만 선택가능  */}
              {/* https://stackoverflow.com/questions/49491569/disable-specific-days-in-material-ui-calendar-in-react */}
              <DateCalendar
                onChange={changeDate}
                onMonthChange={updateMonth}
                // referenceDate={moment().add(0, "months")}
                minDate={moment().add(1, "days")}
                maxDate={moment().add(1, "years")}
                monthsPerRow={undefined}
                views={["day"]}
                disableHighlightToday
                showDaysOutsideCurrentMonth
                shouldDisableDate={(date) => {
                  const stringDate = date.format("YYYY-MM-DD");

                  const totalStock =
                    activityCalendar?.[stringDate] &&
                    Object.values(activityCalendar[stringDate]).reduce(
                      (acc, curr) => acc + curr,
                      0
                    );
                  return !totalStock;
                }}
              />

              {isCalendarLoading && (
                <LoadingSpinner className="absolute top-1/2 h-10" />
              )}
            </TWCardLayout>
            {user && isBookerInfoVisible && (
              <BookerInfoSection
                bookerInfo={bookerInfo}
                setBookerInfo={setBookerInfo}
                validState={bookerValidState}
                validate={bookerValidate}
                exclude={["birthDate"]}
              />
            )}

            <div className="space-y-2">
              {isOptionsLoading ? (
                <>
                  <Skeleton variant="rounded" height={48} />
                  <ActivityOptionSkeleton number={2} />
                </>
              ) : (
                productOptions?.map((productOption) => (
                  <ActivityOption
                    key={productOption.tgiId}
                    productOption={productOption}
                  />
                ))
              )}
            </div>
          </div>

          {isPassengerInfoRequired && productList.length > 0 && (
            <PassengerDetail />
          )}
          <Divider />

          <div className="flex flex-col">{parse(activity.description)}</div>
        </div>
      </ActivityDetailLayout>
    );
  }
);

export default ProductTypeSection;
