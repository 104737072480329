import Layout from "components/layout/Layout";
import LoginContent from "../../components/login/LoginContent";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { ProviderUtils } from "utils/providerUtils";
import TWCardLayout from "components/tailwind/TWCardLayout";

const LoginPage = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return ProviderUtils.loginAvailable ? (
    <Layout
      onBack={() => navigate("/")}
      text={intl.formatMessage({ id: "signup.login" })}
    >
      <LoginContent isModal={false} />
    </Layout>
  ) : (
    <div className="flex min-h-[80vh] w-full items-center justify-center px-4">
      <TWCardLayout className="mt-4 flex w-full">
        <div className="flex items-center justify-center py-3">
          <p className="text-center text-lg font-bold leading-10">
            장기간 이용이 없었습니다.
            <br /> 안전한 이용을 위해 다시 접속해주세요.
          </p>
        </div>
      </TWCardLayout>
    </div>
  );
};

export default LoginPage;
