import { Divider } from "@mui/material";
import Layout from "components/layout/Layout";
import HomeActivityButton, {
  ActivityType,
} from "./components/HomeActivityButton";
import ActivityThumbnail from "./components/ActivityThumbnail";
import ThumbnailSkeleton from "./components/skeleton/ThumbnailSkeleton";
import clsx from "clsx";
import JapanMap from "./components/JapanMap";
import { useGetRecommendedActivityQuery } from "app/apiSlice";
import JRIcon from "assets/icons/jr.png";
import USJIcon from "assets/icons/usj.png";
import BusIcon from "assets/icons/bus.png";

const activityButtonList: ActivityType[] = [
  {
    type: "CATEGORY",
    text: "JR 서일본\n열차 패스",
    color: "#DAFFD3",
    href: `category/jr`,
    icon: JRIcon,
  },
  {
    type: "CATEGORY",
    text: "유니버셜\n스튜디오 재팬",
    color: "#FFDBC3",
    href: `category/usj`,
    icon: USJIcon,
  },
  {
    type: "CATEGORY",
    text: "공항\n리무진 버스",
    color: "#FFF2CD",
    href: `category/bus`,
    icon: BusIcon,
  },
];

const ActivityHome = () => {
  const { data: recommendedActivityList, isLoading } =
    useGetRecommendedActivityQuery(undefined, {
      refetchOnMountOrArgChange: 3600, // 캐시 유지 시간을 1시간으로 설정
    });

  return (
    <Layout text="여행 상품">
      <article className="my-4 space-y-4">
        {/* 주제별 */}
        <section className="space-y-2">
          <h3 className="text-xl font-bold">주제별 상품</h3>

          <section className="grid grid-cols-3 items-stretch gap-1">
            {activityButtonList.map((activityButton) => (
              <HomeActivityButton
                key={activityButton.text}
                activity={activityButton}
              />
            ))}
          </section>
        </section>

        <Divider />
        {/* 지역별 */}
        <section className="space-y-2">
          <h3 className="text-xl font-bold">지역별 상품</h3>

          <JapanMap />
        </section>

        <Divider />

        {/* 추천상품 */}
        <section className="space-y-2">
          <h3 className="text-xl font-bold">추천 상품</h3>
          <section
            className={clsx(
              "grid grid-cols-2 gap-2",
              isLoading && "opacity-50"
            )}
          >
            {isLoading ? (
              <ThumbnailSkeleton direction="column" />
            ) : (
              recommendedActivityList?.content.map((activity, key) => (
                <ActivityThumbnail
                  activity={activity}
                  direction="column"
                  key={`${activity.goodsName}-${key}`}
                />
              ))
            )}
          </section>
        </section>
      </article>
    </Layout>
  );
};

export default ActivityHome;
