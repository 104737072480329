import { Divider, TextField } from "@mui/material";
import clsx from "clsx";
import {
  ActivityReservationContext,
  JTRPassenger,
} from "context/ActivityContextx";
import useValidate, { isFormValid } from "hooks/useValidate";
import TWCardLayout from "components/tailwind/TWCardLayout";
import { useCallback, useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

// const isFormFilled = (form: { [key: string]: string }[]) => {
const isFormFilled = (form: JTRPassenger[]) => {
  return form.every((element) => Object.values(element).every(Boolean));
};

const PassengerDetail = () => {
  const intl = useIntl();
  const {
    passengerDetails,
    setPassengerDetails,
    number,
    setIsPassengersValid,
    passengersDetailRef,
  } = useContext(ActivityReservationContext);

  const getInitialPassengerValidState = useCallback(() => {
    const passengerValidState: Record<`email${number}`, boolean> = {};

    for (let i = 0; i < number; i++) {
      passengerValidState[`email${i}`] = true;
    }
    return passengerValidState;
  }, [number]);

  const [passengerValidState, passengerValidate] = useValidate(
    getInitialPassengerValidState()
  );
  useEffect(() => {
    const passengersValid =
      isFormFilled(passengerDetails) && isFormValid(passengerValidState);

    setIsPassengersValid(passengersValid);
  }, [passengerDetails, passengerValidState, setIsPassengersValid]);

  // 탑승자 정보 변경 핸들러
  const handleChangePassengerDetail =
    (index: number, field: "name" | "email") =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      switch (field) {
        case "email":
          passengerValidate(e);
          break;
        case "name":
          const onlyEnglish = /^[a-zA-Z\s]*$/;
          const isValid = value.match(onlyEnglish);
          if (!isValid) {
            return;
          }
          break;
      }

      setPassengerDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];

        // 특정 인덱스의 탑승자 정보를 복사하고, 필요한 필드를 업데이트합니다.
        updatedDetails[index] = {
          ...updatedDetails[index],
          [field]: field === "name" ? value.toUpperCase() : value,
        };

        return updatedDetails;
      });
    };

  return (
    <>
      <Divider />
      <TWCardLayout
        header={
          <div
            ref={passengersDetailRef}
            className={clsx(
              "min-[390px]:items-normal flex w-full flex-col justify-between min-[390px]:flex-row min-[390px]:items-center"
            )}
          >
            <p className="font-bold">
              <FormattedMessage id="booking.passengerInfo" /> (여권 영문 표기)
            </p>
          </div>
        }
      >
        <div className="w-full space-y-4">
          {passengerDetails.map((passenger, index) => (
            <div key={index} className="space-y-1">
              <TextField
                fullWidth
                label={intl.formatMessage(
                  { id: "booking.passengerName" },
                  { number: index + 1 }
                )}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                name="passengerName"
                value={passenger.name}
                onChange={handleChangePassengerDetail(index, "name")}
                placeholder={intl.formatMessage({
                  id: "booking.passengerNamePlaceholder",
                })}
              />
              <TextField
                type="text"
                fullWidth
                name={`email${index}`}
                value={passenger.email}
                onChange={handleChangePassengerDetail(index, "email")}
                placeholder="email"
                error={
                  !!passenger.email && !passengerValidState[`email${index}`]
                }
                helperText={
                  passenger.email &&
                  !passengerValidState[`email${index}`] &&
                  intl.formatMessage({
                    id: "signup.emailInvalid",
                  })
                }
              />
            </div>
          ))}
        </div>
      </TWCardLayout>
    </>
  );
};

export default PassengerDetail;
