import { Typography } from "@mui/material";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { FormattedMessage, useIntl } from "react-intl";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import TWCardLayout from "components/tailwind/TWCardLayout";
import { useGetMyPointQuery } from "app/apiSlice";
import { memo } from "react";
// import useFetchPoint from "hooks/useFetchPoint";

const MyPointsSection = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  // const point = useFetchPoint();

  const { data: point } = useGetMyPointQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const navigateToPointHistory = () => {
    navigate("/user/point-history");
  };

  const navigateToRegisterCoupon = () => {
    navigate("/user/register-coupon");
  };

  const getHeader = () => {
    return (
      <section className="flex w-full justify-between">
        <h3 className="text-xl font-bold">
          <FormattedMessage id="point.point" />
        </h3>
        <div
          className="flex cursor-pointer items-center"
          onClick={navigateToPointHistory}
        >
          <Typography
            variant="body2"
            color="secondary"
            sx={{ fontWeight: "bold" }}
          >
            <FormattedMessage id="point.earning_history" />
          </Typography>
          <NavigateNextIcon className="cursor-pointer whitespace-nowrap" />
        </div>
      </section>
    );
  };

  const getAdditionalInfo = () => {
    return (
      <section className="flex w-full justify-end">
        <p
          className="cursor-pointer text-sm font-bold text-text-secondary"
          onClick={navigateToRegisterCoupon}
        >
          <FormattedMessage id="point.coupon_registration" />
        </p>
      </section>
    );
  };

  return (
    <TWCardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
      <section className="flex flex-wrap items-center justify-between gap-2">
        <LoyaltyIcon
          fontSize="large"
          color="secondary"
          className="self-start"
        />

        <p className="self-end text-end text-2xl font-bold">
          {(point ?? 0).toLocaleString()}{" "}
          {intl.formatMessage({ id: "payment.koreanWon" })}
        </p>
      </section>
    </TWCardLayout>
  );
};

export default memo(MyPointsSection);
