import { FormattedMessage, useIntl } from "react-intl";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { CostResponse } from "services/trainService";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import useFormatPrice from "hooks/useFormatPrice";
import TWCardLayout from "components/tailwind/TWCardLayout";

interface CostDetailsProps {
  costDetails: CostResponse[];
}

const CostDetailsSection = ({ costDetails }: CostDetailsProps) => {
  const intl = useIntl();
  const formatPrice = useFormatPrice();

  // price/kakaoT data
  const adultPriceDetail = costDetails?.find(
    (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "1"
  );
  const childPriceDetail = costDetails?.find(
    (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "3"
  );
  const pointSavingRate = costDetails?.[0]?.pointSavingRt;
  const kakaoCouponAmount = costDetails?.[0]?.couponAmount;

  const [showInfo, setShowInfo] = useState(false);
  const toggleInfo = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };

  const hideInfo = () => {
    setShowInfo(false);
  };

  useEffect(() => {
    window.addEventListener("click", hideInfo);

    return () => {
      window.removeEventListener("click", hideInfo);
    };
  }, []);

  return (
    <MediaQueryWrapper>
      {/* 카카오 Chip */}
      <div className="relative flex items-center gap-1 max-[400px]:flex-col">
        {Boolean(pointSavingRate) && (
          <p className="mb-1 rounded-full bg-[#E10] px-2 py-1 text-xs font-bold text-white">
            <FormattedMessage
              id={"fareInquiry.reward"}
              values={{ pointSavingRate }}
            />
          </p>
        )}
        {Boolean(kakaoCouponAmount) && (
          <>
            <p className="mb-1 rounded-full bg-[#E10] px-2 py-1 text-xs font-bold text-white">
              <FormattedMessage
                id={"fareInquiry.kakao"}
                values={{ amount: kakaoCouponAmount?.toLocaleString() }}
              />
            </p>

            <InfoIcon
              fontSize="small"
              color="disabled"
              onClick={toggleInfo}
              sx={{ position: "absolute", right: -22, bottom: 4 }}
            />
            {showInfo && (
              <Tooltip title="purchase information" arrow>
                <TWCardLayout className="absolute left-12 top-7 z-10 max-w-[80%] p-2 min-[512px]:top-2">
                  <div className="flex">
                    <p className="text-xs">
                      <FormattedMessage
                        id="train.costDetailedInformation"
                        defaultMessage={intl.formatMessage({
                          id: "This train ticket is part of a Korail travel package and can only be used when purchased in conjunction with a Kakao T coupon",
                        })}
                      />
                    </p>
                  </div>
                </TWCardLayout>
              </Tooltip>
            )}
          </>
        )}
      </div>
      {/* 기차 가격 표시 */}
      <div className="flex gap-1 text-gray-700">
        <span className="text-sm">
          {
            intl
              .formatMessage(
                { id: "fareInquiry.adultPrice" },
                { price: formatPrice(adultPriceDetail?.csmrFare) }
              )
              .split(":")[0]
          }
          :{" "}
        </span>

        <div className="relative flex items-center gap-2">
          <span
            className="text-sm"
            // sx={{
            //   textDecorationColor: theme.palette.error.main,
            // }}
          >
            {
              intl
                .formatMessage(
                  { id: "fareInquiry.adultPrice" },
                  { price: formatPrice(adultPriceDetail?.csmrFare) }
                )
                .split(":")[1]
            }
          </span>
          {/* {ProviderUtils.discountAvailable && isDiscount && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography
                component="div"
                variant="body2"
                sx={{
                  color: theme.palette.error.main,
                  display: "flex",
                  alignItems: "center",
                  width: "max-content",
                }}
              >
                {adultPriceDetail?.sellDscntRt}%
                {ProviderUtils.isGME ? (
                  isKorean ? (
                    " 할인"
                  ) : (
                    " off"
                  )
                ) : (
                  <SouthIcon
                    fontSize="small"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: theme.palette.error.main,
                    }}
                  />
                )}
              </Typography>
            </Stack>
          )} */}
        </div>
      </div>
      <span className="text-sm text-gray-700">
        {intl.formatMessage(
          { id: "fareInquiry.childPrice" },
          { price: formatPrice(childPriceDetail?.mrkFare) }
        )}
      </span>
    </MediaQueryWrapper>
  );
};

export default CostDetailsSection;

const MediaQueryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 512px) {
    align-items: center;
  }
`;
