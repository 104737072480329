import Header from "components/layout/Header";
import BookingForm from "./sections/BookingForm";
import Banner from "./sections/Banner";
import BackgroundImage from "./sections/bgImage/BackgroundImage";
import { useEffect } from "react";
import Footer from "components/layout/Footer";
import { StorageUtils } from "utils/storageUtils";
import { ProviderUtils } from "utils/providerUtils";
import history from "history/browser";
import { closeWebview } from "utils/lottecardUtils";
import { useAppDispatch } from "app/hooks";
import { deleteMyOrders, resetFilterSetting } from "app/myOrdersSlice";
import LachaRewardBanner from "./sections/LachaRewardBanner";
import TWCardLayout from "components/tailwind/TWCardLayout";
import { useNavigate } from "react-router-dom";
import AttractionsIcon from "@mui/icons-material/Attractions";
import { __STAGING__ } from "const/properties";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const [NoticeModal] = useModalSheet({
  //   defaultVisible: sessionStorage.getItem("NoticeModalRead") !== "true",
  //   callbackOk: () => {
  //     sessionStorage.setItem("NoticeModalRead", "true");
  //   },
  // });

  // // 코레일 작업 완료 일정
  // const maintenanceEndTime = new Date(2025, 0, 24, 15, 30);
  // const isMaintenanceDone = new Date() > maintenanceEndTime;

  useEffect(() => {
    dispatch(deleteMyOrders());
    dispatch(resetFilterSetting());

    StorageUtils.TrainTab.remove();
    StorageUtils.ScrollY.remove();
  }, [dispatch]);

  useEffect(() => {
    // navigate(window.location.pathname); 이 코드 있으면 안 됨 - url 복붙 하면 / 로 이동함

    const listenBackEvent = () => {
      if (ProviderUtils.isHanpass) {
        StorageUtils.HanpassBack.remove();
        window.location.href = "http://finish";
      }
      if (ProviderUtils.isLottecard) {
        closeWebview();
      }
    };

    const historyEvent = history.listen(({ action }) => {
      if (action === "POP") {
        listenBackEvent();
      }
    });

    return () => {
      historyEvent();
      // window.history.replaceState(null, "");
    };
  }, []);

  return (
    <>
      <Header />
      <BackgroundImage />

      <section className="relative mx-auto mb-20 min-h-[80vh] max-w-md space-y-4 px-4">
        <BookingForm />

        {__STAGING__ && (
          <TWCardLayout>
            <div
              className="flex items-center justify-center gap-2"
              onClick={() => {
                navigate("/activity");
              }}
            >
              <AttractionsIcon color="primary" className="!text-5xl" />
              <p className="text-xl font-bold text-text-secondary">
                일본 여행 상품 예약하기
              </p>
            </div>
          </TWCardLayout>
        )}

        <LachaRewardBanner />

        {ProviderUtils.provider?.provider !== "lottecard" && <Banner />}
      </section>

      {/* {!isMaintenanceDone && (
        <NoticeModal modal>
          <div className="space-y-4">
            <p className="font-bold">
              [공지] 코레일 시스템 조정에 따른 작업 일정 알림
            </p>
            <p className="break-normal text-start">
              코레일 열차 할인율 적용 관련 시스템 조정 작업이 진행됩니다. 조정
              작업 중 열차 예약이 불가하오니 이용에 참고 부탁드립니다.
              <br />
              <br />
              -일시: 2025.01.24.(금) 15:00 - 15:20
            </p>
          </div>
        </NoticeModal>
      )} */}

      <Footer />
    </>
  );
};

export default HomePage;
