import { Grid, Typography } from "@mui/material";
import LachaCardLogo from "assets/images/logo/lacha_card_logo.png";
import { Box } from "@mui/system";
import CardLogo from "./CardLogo";
import TWCardLayout from "components/tailwind/TWCardLayout";

interface CardBackProps {
  cvc: string;
}

const CardBack = ({ cvc }: CardBackProps) => {
  return (
    <TWCardLayout className="mx-1 w-full pt-0">
      <Grid
        container
        alignItems={"stretch"}
        sx={{
          minHeight: "120px",
          maxHeight: "240px",
          height: "calc((100vw - 32px) / 2.1)",
        }}
      >
        <Grid
          item
          xs={12}
          alignContent="end"
          sx={{
            height: "27%",
          }}
        >
          <div className="relative right-4 flex h-full w-[calc(100%_+_32px)] justify-end bg-[#111e] p-4">
            <CardLogo src={LachaCardLogo} alt="LACHA Logo" />
          </div>
        </Grid>
        <Grid
          item
          alignContent="end"
          sx={{
            bgcolor: "silver",
            width: "90%",
            height: "25%",
            px: 1,
            borderRadius: 0.2,
          }}
        >
          <div className="justify-end text-end">
            <p className="text-xs">CVC</p>

            <p className="font-bold italic">{cvc}</p>
          </div>
        </Grid>
      </Grid>
    </TWCardLayout>
  );
};

export default CardBack;
