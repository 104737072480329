import React, { useState } from "react";
import { TextField, FormControlLabel, Checkbox } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { PassengerDetail } from "app/reservationSlice";
import { ValidStateType } from "hooks/useValidate";
import { PassengerValidState } from "../PaymentBookingPage";
import { BookerInfo } from "utils/reservationUtils";
import { ProviderUtils } from "utils/providerUtils";
import clsx from "clsx";
import TWCardLayout from "components/tailwind/TWCardLayout";

export type PassengerDetailField = "name" | "birthDate";

interface PassengerInfoSectionProps {
  agencyCode: string;
  setAgencyCode: React.Dispatch<React.SetStateAction<string>>;
  bookerDetails: BookerInfo;
  passengerDetails: PassengerDetail[];
  setPassengerDetails: React.Dispatch<React.SetStateAction<PassengerDetail[]>>;
  onChangePassengerDetail: (
    index: number,
    field: PassengerDetailField
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  validState: ValidStateType<PassengerValidState>;
}

const PassengerInfoSection = ({
  agencyCode,
  setAgencyCode,
  bookerDetails,
  passengerDetails,
  setPassengerDetails,
  onChangePassengerDetail,
  validState,
}: PassengerInfoSectionProps) => {
  const intl = useIntl();

  const [isSameAsBooker, setIsSameAsBooker] = useState(false);

  const handleAgencyCodeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAgencyCode(e.target.value);
  };

  const handleSameAsBookerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setIsSameAsBooker(checked);

    if (checked) {
      // 첫 번째 탑승자 정보를 예매자 정보로 업데이트
      setPassengerDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];
        updatedDetails[0] = {
          ...updatedDetails[0],
          name: bookerDetails.name,
          birthDate: bookerDetails.birthDate || "",
        };
        return updatedDetails;
      });
    }
  };

  const getHeader = () => {
    return (
      <div
        className={clsx(
          "min-[390px]:items-normal flex w-full flex-col justify-between min-[390px]:flex-row min-[390px]:items-center"
        )}
      >
        <p className="font-bold">
          <FormattedMessage id="booking.passengerInfo" />
        </p>

        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={isSameAsBooker}
              onChange={handleSameAsBookerChange}
            />
          }
          label={intl.formatMessage({ id: "booking.sameAsPurchaser" })}
          className="!m-0 self-end p-0 text-end"
        />
      </div>
    );
  };

  const getAdditionalInfo = () => {
    return (
      <div className="grid w-full grid-cols-3 items-center gap-4">
        <p className="col-span-1 font-bold">
          {intl.formatMessage({ id: "booking.agencyCode" })}
        </p>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          name="agencyCode"
          value={agencyCode}
          onChange={handleAgencyCodeChange}
          className="col-span-2"
        />
      </div>
    );
  };

  return (
    <TWCardLayout
      header={getHeader()}
      additionalInfo={ProviderUtils.isHanpass ? getAdditionalInfo() : undefined}
    >
      <div className="w-full space-y-4">
        {passengerDetails.map((passenger, index) => (
          <div key={index} className="space-y-1">
            <TextField
              fullWidth
              label={intl.formatMessage(
                { id: "booking.passengerName" },
                { number: index + 1 }
              )}
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              name="passengerName"
              value={passenger.name}
              onChange={onChangePassengerDetail(index, "name")}
              placeholder={intl.formatMessage({
                id: "booking.passengerNamePlaceholder",
              })}
            />
            <TextField
              type="text"
              fullWidth
              name={`birthDate${index}`}
              value={passenger.birthDate}
              onChange={onChangePassengerDetail(index, "birthDate")}
              placeholder="YYYYMMDD"
              error={!!passenger.birthDate && !validState[`birthDate${index}`]}
              helperText={
                passenger.birthDate &&
                !validState[`birthDate${index}`] &&
                intl.formatMessage({
                  id: "booking.invalidDoB",
                })
              }
            />
          </div>
        ))}
      </div>
    </TWCardLayout>
  );
};

export default PassengerInfoSection;
