import CustomButton from "components/button/CustomButton";
import TWCardLayout from "components/tailwind/TWCardLayout";
import { createContext, useEffect, useState } from "react";

type DialogContextType = {
  // confirm: (message?: string) => void;
  confirm: (message?: string) => Promise<boolean>;
  alert: (message?: string) => Promise<boolean>;
};

export const DialogContext = createContext<DialogContextType>({
  confirm: () => new Promise((_, reject) => reject()),
  alert: () => new Promise((_, reject) => reject()),
  // confirm: () => {},
});

//

interface DialogState {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const DialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [confirmState, setConfirmState] = useState<DialogState>();
  const [alertState, setAlertState] = useState<DialogState>();

  const confirm = (message?: string): Promise<boolean> => {
    return new Promise((resolve) => {
      setConfirmState({
        message: message || "",
        onConfirm: () => {
          // ok 클릭한 경우, 다이얼로그 닫고 true로 Promise 종료
          setConfirmState(undefined);
          resolve(true);
          // resolve("Confirmed");
        },
        onCancel: () => {
          // cancel 클릭한 경우, 다이얼로그 닫고 false로 Promise 종료
          setConfirmState(undefined);
          resolve(false);
          // resolve("Cancelled");
        },
      });
    });
  };

  const alert = (message?: string): Promise<boolean> => {
    return new Promise((resolve) => {
      setAlertState({
        message: message || "",
        onConfirm: () => {
          // ok 클릭한 경우, 다이얼로그 닫고 true로 Promise 종료
          setAlertState(undefined);
          resolve(true);
          // resolve("Confirmed");
        },
        onCancel: () => {
          // cancel 클릭한 경우, 다이얼로그 닫고 false로 Promise 종료
          setAlertState(undefined);
          resolve(false);
          // resolve("Cancelled");
        },
      });
    });
  };

  useEffect(() => {
    if (confirmState || alertState) {
      document.body.style.overflow = "hidden"; // 스크롤 막기
    } else {
      document.body.style.overflow = ""; // 스크롤 활성화
    }
    return () => {
      document.body.style.overflow = ""; // 클린업
    };
  }, [alertState, confirmState]);

  return (
    <DialogContext.Provider value={{ confirm, alert }}>
      {children}
      {/* state 여부에 따라 Confirm 다이얼로그 띄우기 */}
      {confirmState && (
        <Confirm
          message={confirmState.message}
          onConfirm={confirmState.onConfirm}
          onCancel={confirmState.onCancel}
        />
      )}
      {alertState && (
        <Alert
          message={alertState.message}
          onConfirm={alertState.onConfirm}
          onCancel={alertState.onCancel}
        />
      )}
    </DialogContext.Provider>
  );
};

export default DialogProvider;

const Confirm = ({ message, onConfirm, onCancel }: DialogState) => {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onCancel();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => document.removeEventListener("keydown", handleEscape);
  }, [onCancel]);

  return (
    <div
      className="fixed left-0 top-0 z-50 h-full w-full bg-black/40"
      onClick={onCancel}
    >
      <TWCardLayout className="fixed left-1/2 top-1/2 z-50 w-[90%] -translate-x-1/2 -translate-y-1/2 transform">
        {/* <h2 className="title">Confirm</h2> */}
        <p className="text-lg font-bold">{message}</p>
        <div className="mt-4 flex w-full gap-2">
          <CustomButton
            size="medium"
            color="error"
            variant="outlined"
            onClick={onCancel}
          >
            cancel
          </CustomButton>
          <CustomButton size="medium" variant="outlined" onClick={onConfirm}>
            ok
          </CustomButton>
        </div>
      </TWCardLayout>
    </div>
  );
};

const Alert = ({ message, onConfirm, onCancel }: DialogState) => {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onCancel();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => document.removeEventListener("keydown", handleEscape);
  }, [onCancel]);

  return (
    <div
      className="fixed left-0 top-0 z-50 h-full w-full bg-black/40"
      onClick={onCancel}
    >
      <TWCardLayout className="fixed left-1/2 top-1/2 z-50 w-[90%] -translate-x-1/2 -translate-y-1/2 transform">
        {/* <h2 className="title">Confirm</h2> */}
        <p className="text-lg font-bold">{message}</p>
        <div className="mt-4 flex w-full justify-center">
          <CustomButton
            size="medium"
            variant="outlined"
            onClick={onConfirm}
            className="!w-1/2"
          >
            ok
          </CustomButton>
        </div>
      </TWCardLayout>
    </div>
  );
};
