import { Typography, Chip, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useIntlFormatPrice from "hooks/useFormatPrice";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { OrderStatus, OrderType } from "types/orderType";
import useLanguages from "hooks/useLanguages";
import useOrderStatusText from "hooks/useOrderStatusText";
import OrderThumbnailContent from "./OrderThumbnailContent";
import TWCardLayout from "components/tailwind/TWCardLayout";
import clsx from "clsx";
import PaymentService from "services/paymentService";

type OrderThumbnailProps = {
  order: OrderType;
  withoutBorder?: boolean;
};

const getChipColor = (status: OrderStatus) => {
  switch (status) {
    case "CANCELED":
      return "cancelled";
    case "PENDING":
      return "pending";
    case "COMPLETE":
    default:
      return "complete";
  }
};

const OrderThumbnail = ({
  order,
  withoutBorder = false,
}: OrderThumbnailProps) => {
  // hooks
  const { isKorean } = useLanguages();
  const navigate = useNavigate();
  const theme = useTheme();
  const formatPrice = useIntlFormatPrice();
  const formatDateTime = useFormatDateToLocaleString();

  const isPending = order.orderStatus === "PENDING";

  // Actions
  // 상세보기 클릭 핸들러
  const handleDetailClick = async () => {
    if (order.orderId) {
      // TODO: 이렇게 되면, Detail 페이지로 이동할 때마다, paymentId를 생성함
      // detail만 확인하고 결제는 하지 않는 경우가 많기 때문에 비효율적임. 서버 부하
      // 문제 해결하려면 payment 페이지에서 orderIds Array로 paymentId를 생성하도록 수정해야 함.

      if (isPending) {
        try {
          const paymentId = await PaymentService.createPaymentId([
            order.orderId!,
          ]);
          navigate(`/payment/${paymentId}`);
        } catch (error) {
          console.error(error);
        }
      } else {
        navigate(`/order-details/${order.orderId}`);
      }
    }

    // const orderType = order.productType;
    // switch (orderType) {
    //   case "TRAIN":
    //     navigate(`/order-details/${order.orderId}`);
    //     break;
    //   case "TRAVEL":
    //     navigate(`/activity/order-details/${order.orderId}`);
    //     break;

    //   default:
    //     break;
    // }
  };

  // 주문 상태 코드를 텍스트로 변환하는 함수
  const getOrderStatusText = useOrderStatusText();

  const getHeader = () => {
    // 주문 상태와 생성 날짜 표시
    return (
      <div className="w-full space-y-2">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <Chip
              label={getOrderStatusText(order.orderStatus)}
              color={`${getChipColor(order.orderStatus)}`}
              variant={order.orderStatus === "COMPLETE" ? "filled" : "outlined"}
              className="self-start font-bold"
            />
            {isKorean && (
              <p className="break-words text-sm text-text-secondary">
                {formatDateTime(order.orderCreatedDate)}
              </p>
            )}
          </div>

          {/* 상세보기 링크 */}
          <NavigateNextIcon
            style={{
              cursor: "pointer",
              whiteSpace: "nowrap",
            }}
            onClick={handleDetailClick}
            sx={{ alignSelf: "center" }}
          />
        </div>
        {!isKorean && (
          <p className="break-words text-end text-sm text-text-secondary">
            {formatDateTime(order.orderCreatedDate)}
          </p>
        )}
      </div>
    );
  };

  const getAdditionalInfo = () => {
    // 총 가격 표시
    return (
      <Typography
        color={
          theme.palette.mode === "light" ? "primary.main" : "primary.light"
        }
        className="w-full text-right !text-xl !font-bold"
      >
        {/* {formatPrice(order.totalPrice)} // 할인금액  */}
        {formatPrice(order.originalPrice)}
      </Typography>
    );
  };

  return (
    <TWCardLayout
      header={getHeader()}
      additionalInfo={getAdditionalInfo()}
      className={clsx({ "border-none p-0 shadow-none": withoutBorder })}
    >
      <OrderThumbnailContent reservationDetails={order} />
    </TWCardLayout>
  );
};

export default OrderThumbnail;
