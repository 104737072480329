import React from "react";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { BookerInfo } from "utils/reservationUtils";
import { ValidStateType } from "hooks/useValidate";
import useLanguages from "hooks/useLanguages";
import TWCardLayout from "components/tailwind/TWCardLayout";

interface BookerInfoSectionProps {
  bookerInfo: BookerInfo;
  setBookerInfo: React.Dispatch<React.SetStateAction<BookerInfo>>;
  validState: ValidStateType<BookerInfo>;
  // validState: ValidStateType<FormValidState>;
  validate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  exclude?: (keyof BookerInfo)[];
}

const BookerInfoSection = ({
  bookerInfo,
  setBookerInfo,
  validState,
  validate,
  exclude,
}: BookerInfoSectionProps) => {
  const intl = useIntl();
  const { isKorean } = useLanguages();

  // 아래 코드를 실행하면 initial에 값이 없으면 bookerInfo에 나오지 않는 문제 있음
  // const {
  //   name: hasName,
  //   phone: hasPhone,
  //   email: hasEmail,
  //   birthDate: hasBirthDate,
  // } = Object.fromEntries(
  //   Object.entries(bookerInfo).map(([key, value]) => [key, !!value])
  // );

  // exclude에 있는 경우만 false로 설정
  const {
    name: hasName,
    phone: hasPhone,
    email: hasEmail,
    birthDate: hasBirthDate,
  } = Object.fromEntries(
    Object.keys(bookerInfo).map((key) => [
      key,
      !exclude?.includes(key as keyof BookerInfo),
    ])
  );

  // 예매자 정보 변경 핸들러
  const handleBookerInfoChange =
    (field: keyof BookerInfo) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      // validate
      switch (field) {
        case "birthDate":
        case "name":
        case "phone":
        case "email":
          validate(e);
          break;
        default:
          break;
      }

      setBookerInfo({ ...bookerInfo, [field]: value });
    };

  const getHeader = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="end"
        sx={{ width: "100%" }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          <FormattedMessage id="booking.customerInfo" />
        </Typography>
        <Typography variant="body2" color="error" sx={{ textAlign: "end" }}>
          * {isKorean ? "최초 1회 입력" : "Enter only once"}
        </Typography>
      </Stack>
    );
  };

  return (
    <TWCardLayout header={getHeader()} className="w-full">
      <Grid container direction="column" spacing={1}>
        {hasName && (
          <Grid item container spacing={2} alignItems={"center"}>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "start",
                gap: 0.5,
              }}
            >
              <span style={{ color: "red" }}>*</span>
              <Typography variant="caption">
                {intl.formatMessage({ id: "booking.customer" })}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                name="name"
                value={bookerInfo.name}
                onChange={handleBookerInfoChange("name")}
                inputProps={{ maxLength: 50 }}
                placeholder={intl.formatMessage({ id: "signup.enterName" })}
                error={!validState.name}
                helperText={
                  !bookerInfo.name &&
                  !validState.name &&
                  intl.formatMessage({ id: "signup.enterName" })
                }
              />
            </Grid>
          </Grid>
        )}

        {hasPhone && (
          <Grid item container spacing={1} alignItems={"center"}>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "start",
                gap: 0.5,
              }}
            >
              <span style={{ color: "red" }}>*</span>
              <Typography variant="caption">
                {intl.formatMessage({ id: "booking.contact" })}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                type="tel" // Use "tel" for telephone numbers
                name="phone"
                value={bookerInfo.phone}
                onChange={handleBookerInfoChange("phone")}
                placeholder={intl.formatMessage({ id: "signup.enterPhone" })}
                inputProps={{ pattern: "\\d*", maxLength: 50 }} // Regex pattern for only digits
                error={!validState.phone}
                helperText={
                  !!bookerInfo.phone && !validState.phone
                    ? intl.formatMessage({ id: "signup.phoneInvalid" })
                    : !bookerInfo.phone && !validState.phone
                      ? intl.formatMessage({ id: "signup.enterPhone" })
                      : undefined
                }
              />
            </Grid>
          </Grid>
        )}

        {hasEmail && (
          <Grid item container spacing={2} alignItems={"center"}>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "start",
                gap: 0.5,
              }}
            >
              <span style={{ color: "red" }}>*</span>
              <Typography variant="caption">
                {intl.formatMessage({ id: "booking.email" })}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                name="email"
                value={bookerInfo.email}
                onChange={handleBookerInfoChange("email")}
                inputProps={{ maxLength: 50 }}
                placeholder={intl.formatMessage({ id: "signup.enterEmail" })}
                inputMode="email"
                error={!validState.email}
                helperText={
                  !!bookerInfo.email && !validState.email
                    ? intl.formatMessage({ id: "signup.emailInvalid" })
                    : !bookerInfo.email && !validState.email
                      ? intl.formatMessage({ id: "signup.enterEmail" })
                      : undefined
                }
              />
            </Grid>
          </Grid>
        )}

        {hasBirthDate && (
          <Grid item container spacing={2} alignItems={"center"}>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "start",
                gap: 0.5,
              }}
            >
              <span style={{ color: "red" }}>*</span>
              <Typography variant="caption">
                {intl.formatMessage({ id: "booking.dateOfBirth" })}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                name="birthDate"
                value={bookerInfo.birthDate}
                onChange={handleBookerInfoChange("birthDate")}
                inputProps={{ maxLength: 8 }}
                placeholder="YYYYMMDD"
                inputMode="text"
                error={!validState.birthDate}
                helperText={
                  !!bookerInfo.birthDate && !validState.birthDate
                    ? intl.formatMessage({ id: "booking.invalidBirthDate" })
                    : !bookerInfo.birthDate && !validState.birthDate
                      ? intl.formatMessage({ id: "booking.birthDate" })
                      : undefined
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </TWCardLayout>
  );
};

export default BookerInfoSection;
