import React, { useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  useTheme,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ThemeToggle from "components/ThemeToggle";
import Footer from "./Footer";
import { ProviderUtils } from "utils/providerUtils";
import CloseIcon from "@mui/icons-material/Close";
import { closeWebview } from "utils/lottecardUtils";
import { HEADER_HEIGHT } from "const/layout";
import LanguageSelectIcon from "components/language_selector/LanguageSelectIcon";
import LogoutButton from "components/button/LogoutButton";
import { useMyInfoQuery } from "app/apiSlice";

interface LayoutProps {
  children: React.ReactNode;
  text?: string | React.ReactElement;
  onBack?: () => void;
  footer?: boolean;
}

const Layout = ({ children, text, onBack, footer = true }: LayoutProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const navBar = useRef<HTMLDivElement>(null);
  const { data: user } = useMyInfoQuery();

  useEffect(() => {
    const id = setTimeout(() => {
      navBar.current?.scrollIntoView({
        behavior: "instant",
        block: "start",
      });
    }, 0);

    return () => {
      clearTimeout(id);
    };
  }, []);

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        overflow: "hidden",
        minHeight: "calc(100vh - env(safe-area-inset-top))",
      }}
    >
      <AppBar
        ref={navBar}
        position="sticky"
        sx={{
          bgcolor: theme.palette.white.main,
          boxShadow: `0 2px 4px rgba(0,0,0,0.1)`,
          direction: "ltr",
          position: "fixed",

          width: "100%",
          height: `calc(env(safe-area-inset-top) + ${HEADER_HEIGHT}px)`,
          paddingTop: `env(safe-area-inset-top)`,
        }}
      >
        <Toolbar
          variant="dense"
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <IconButton
            onClick={onBack ? onBack : () => navigate(-1)}
            size="small"
            edge="start"
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <h1 className="grow-1 absolute left-1/2 -translate-x-1/2 text-center font-bold text-black">
            {text}
          </h1>
          {ProviderUtils.isCard && user && (
            <div className="absolute right-6 flex items-center gap-2 text-black">
              <LogoutButton size="small" icon={false} />
              <LanguageSelectIcon type="ENG" />
            </div>
          )}
          {/* {ProviderUtils.darkModeAvailable && <ThemeToggle />} */}
          {ProviderUtils.isLottecard &&
            window.location.pathname === "/order-history" && (
              <Stack color={"black.main"} onClick={closeWebview}>
                <CloseIcon />
              </Stack>
            )}
        </Toolbar>
      </AppBar>

      <main
        className="m-auto mt-8 w-full max-w-lg p-4"
        // sx={{
        //   display: "flex",
        //   flexDirection: "column",
        //   p: 2,
        //   mt: 4,
        //   width: "100%",
        // }}
      >
        {children}
      </main>
      {footer && <Footer />}
    </Stack>
  );
};

export default Layout;
