import { Grid, TextField, Typography } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { updatePoint } from "app/userSlice";
import CustomButton from "components/button/CustomButton";
import Layout from "components/layout/Layout";
import useModalSheet from "hooks/overlay/useModalSheet";
import TWCardLayout from "components/tailwind/TWCardLayout";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import UserService from "services/userService";

const RegisterPoint = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [couponNumber, setCouponNumber] = useState("");
  const [isError, setIsError] = useState(false);
  const [ModalSheet, setModalVisible] = useModalSheet();
  // const notices: string[] = [];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    const upperCasedValue = value.toUpperCase();
    setIsError(false);
    setCouponNumber(upperCasedValue);
  };

  const getHeader = () => {
    return (
      <h5 className="text-lg font-bold">
        {intl.formatMessage({ id: "point.coupon_registration" })}
      </h5>
    );
  };

  const register = async () => {
    try {
      await UserService.registerPoint(couponNumber);
      setCouponNumber("");
      setModalVisible(true);

      // 쿠폰 등록 후에 lacha point 최신화
      UserService.getPoint().then((point) => {
        dispatch(updatePoint(point));
      });
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  return (
    <Layout text={intl.formatMessage({ id: "point.coupon_registration" })}>
      <TWCardLayout header={getHeader()} className="my-4">
        <div className="grid grid-cols-12 items-start justify-between gap-2">
          <TextField
            className="col-span-8"
            fullWidth
            name="name"
            value={couponNumber}
            onChange={handleChange}
            placeholder={intl.formatMessage({
              id: "point.enter_coupon_number",
            })}
            error={isError}
            helperText={
              isError && (
                <span>
                  {intl.formatMessage({ id: "point.invalid_coupon_number" })}
                </span>
              )
            }
            InputProps={{
              inputProps: { maxLength: 16 },
              sx: {
                height: "48px",
              },
            }}
          />
          <CustomButton size="large" onClick={register} className="col-span-4">
            {intl.formatMessage({ id: "point.application" })}
          </CustomButton>
        </div>
      </TWCardLayout>

      <ModalSheet modal>
        <p className="text-lg font-medium">
          {intl.formatMessage({ id: "point.coupon_registered" })}
        </p>
      </ModalSheet>
      {/* {notices.length > 0 && (
        <Stack sx={{ px: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            유의사항
          </Typography>
          {notices.map((notice) => (
            <ListItem key={notice} sx={{ px: 0, py: 0.5 }}>
              <Stack direction="row" alignItems="flex-start">
                <span style={{ marginInlineEnd: "8px" }}>•</span>
                notice
              </Stack>
            </ListItem>
          ))}
        </Stack>
      )} */}
    </Layout>
  );
};

export default RegisterPoint;
