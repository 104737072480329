import clsx from "clsx";
import TWCardLayout from "components/tailwind/TWCardLayout";
import { useNavigate } from "react-router-dom";

export interface ActivityType {
  type: "CATEGORY" | "REGION";
  text: string;
  color: string;
  imgUrl?: string;
  href: string;
  icon: string;

  // category1?: string;
  // category2?: string;
}

interface HomeActivityButtonProps {
  activity: ActivityType;
}

const HomeActivityButton = ({ activity }: HomeActivityButtonProps) => {
  const navigate = useNavigate();

  // const getBgColor = () => {
  //   const type = activity.type;
  //   switch (type) {
  //     case "CATEGORY":
  //       return activity.color;
  //     case "REGION":
  //       return "#FFFFFF";
  //   }
  // };

  // const getTextColor = () => {
  //   const type = activity.type;
  //   switch (type) {
  //     case "CATEGORY":
  //       return "#333";
  //     case "REGION":
  //       return activity.color;
  //   }
  // };

  return (
    <TWCardLayout
      className={clsx(`border-none p-0`, {
        // tailwind는 빌드 시 클래스 이름을 사용하여 style을 적용함. 아래처럼 동적으로 성생되는 클래스는
        // tailwind build process에서 인식되지 않아 style 적용되지 않음.
        // => tailwind.config에서 safelist option을 사용해 빌드 시 클래스 포함되도록 설정
        [`bg-[${activity.color}]`]: activity.type === "CATEGORY",
      })}
    >
      <div
        // className="flex min-h-24 flex-col items-center justify-center space-y-2 rounded-2xl p-2 text-center active:scale-[1.02]"
        className="flex min-h-24 flex-col justify-between space-y-2 rounded-2xl p-2 pb-0 active:scale-[1.02] max-[365px]:justify-center max-[365px]:pb-2"
        // style={{ backgroundColor: getBgColor(), color: getTextColor() }}
        onClick={() => navigate(activity.href)}
      >
        <h3 className="select-none whitespace-pre-line break-keep font-bold leading-tight max-[365px]:text-center">
          {activity.text}
        </h3>
        {activity.href !== "category/usj" && (
          <div className="flex justify-end">
            <img
              src={activity.icon}
              alt=""
              className="relative top-[6px] justify-end max-[365px]:hidden"
            />
          </div>
        )}
      </div>
    </TWCardLayout>
  );
};

export default HomeActivityButton;
